import React, { useEffect, useState } from 'react'
import { AlumniCornerHeaderComponent } from '../components/alumni-corner-header/alumni-corner-header.component'
import BatchMate from '../types/BatchMateDTO';
import { FaSearch } from 'react-icons/fa'
import { BatchMateItemComponent } from '../components/batch-mate-item/batch-mate-item.component';
import { postData } from '../api/AxisConfig';
import { getURL } from '../api/URL';
import { getProfile } from '../common/auth-constant';

export const AlumniFinderLayout = () => {

    const [batchMates, setBatchMates] = useState<BatchMate[]>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const prof: any = getProfile();
    const userProfile = JSON.parse(prof);

    useEffect(() => {
        const formData = new FormData();
        formData.append('profile_id', userProfile["profile_id"]);
        formData.append('token', "");

        postData(getURL("ALUMNI_FINDER", "ALUMNI_LOGIN", [], true), formData, (resp: any) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                console.log(resp);
                const listProfile = resp.data.data.filter((i: any) => i.profile_id != userProfile["profile_id"]);
                setBatchMates(listProfile);
            }

        },
            (error: any) => {
                console.log(error);
            }
        );

    }, []);

    // Filter batchmates based on the search term
    const filteredBatchMates = batchMates?.filter(
        (item) =>
            item.fname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.lname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div>
            <div className='flex flex-col'>
                <AlumniCornerHeaderComponent title='Alumni Finder' />
                {/* Search Bar */}
                <div className="w-full mb-4 relative mt-4">
                    <input
                        type="text"
                        placeholder="Search Alumni"
                        className="w-full pl-10 pr-4 py-2 rounded-md border border-gray-300 focus:outline-cyan-400"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                        <FaSearch className='text-xl text-cyan-400 font-extrabold ' />
                    </span>
                </div>
                <div className="flex flex-wrap -mx-4 mt-4">
                    {
                        filteredBatchMates?.map(item => {
                            return <BatchMateItemComponent {...item} key={item.profile_id} />
                        })}
                </div>

            </div>
        </div>
    )
}
