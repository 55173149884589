import axios from "axios";

export const getData = (serviceUrl, successCallback, errorCallback) => {
  axios
    .get(serviceUrl)
    .then((resp) => {
      successCallback(resp);
      return resp;
    })
    .catch((error) => {
      errorCallback(error);
    });

};

export const postData = (serviceUrl, data, successCallback, errorCallback) => {
  axios
    .post(serviceUrl, data)
    .then((resp) => {
      successCallback(resp);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const postBody = (serviceUrl, data, successCallback, errorCallback) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(serviceUrl, data, config)
    .then((resp) => {
      successCallback(resp);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const putData = (serviceUrl, data, successCallback, errorCallback) => {
  axios
    .put(serviceUrl, data)
    .then((resp) => {
      successCallback(resp);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const putBody = (serviceUrl, data, successCallback, errorCallback) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .put(serviceUrl, data, config)
    .then((resp) => {
      successCallback(resp);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const deleteRecord = (serviceUrl, successCallback, errorCallback) => {
  axios
    .delete(serviceUrl)
    .then((resp) => {
      successCallback(resp);
    })
    .catch((error) => {
      errorCallback(error);
    });
};
