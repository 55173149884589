import React, { useState } from 'react'
import { LoginComponent } from '../components/login/login.component';
import { RegisterComponent } from '../components/register/register.component';

export const RegisterLoginLayout = () => {

    const [isLoginOpen, setIsLoginOpen] = useState(true);

    return (
        <div className='container my-4 mx-auto px-8 md:px-12 max-w-screen-md'>
            <div className=" bg-gray-300 shadow flex flex-col">
                <div className='grid grid-cols-2 text-base font-semibold justify-items-center'>
                    <div className={isLoginOpen ? 'signin-container p-4  bg-white shadow w-full' : 'signin-container p-4 shadow w-full'}>
                        <button className={isLoginOpen ? 'text-center w-full font-bold' : 'text-center w-full text-gray-500'} onClick={() => {

                            setIsLoginOpen(true);
                        }}>Login</button>
                    </div>
                    <div className={!isLoginOpen ? 'signup-container p-4  bg-white shadow w-full' : 'signup-container p-4 shadow w-full'}>
                        <button className={!isLoginOpen ? 'text-center w-full font-bold' : 'text-center w-full text-gray-500'} onClick={() => {

                            setIsLoginOpen(false);
                        }}>Register</button>
                    </div>
                </div>
                <div className=''>
                    {
                        isLoginOpen ? <LoginComponent /> : <RegisterComponent />
                    }
                </div>
            </div>
        </div>
    )
}
