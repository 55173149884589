import React from 'react'

import { HomeLayout } from '../layouts/home.layout'

export const HomePage = () => {
    return (
        <div className=''>

            <div className='flex flex-col'>
                <HomeLayout />
            </div>

        </div>
    )
}
