import React from 'react'
import { AlumniCornerNotificationLayout } from '../layouts/alumni-corner-notification.layout'

export const AlumniCornerNotificationPage = () => {
    return (
        <div>
            <AlumniCornerNotificationLayout />
        </div>
    )
}
