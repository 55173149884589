import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { SectionHeaderComponent } from '../components/section-header/section-header.component'

export const AlumniCornerLayout = () => {

    const [activeLink, setActiveLink] = useState(0);

    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className='flex flex-row'>
                <SectionHeaderComponent title='Alumni Corner' showViewMoreButton={false} navigationPath='/' />
            </div>
            <div className=" bg-gray-300 shadow flex flex-col p-4">

                <div className='lg:hidden p-4'>
                    <div className='left-nav-menu flex flex-col'>
                        <Link
                            className={`p-2 text-center w-4/4 m-1 text-sm text-white hover:bg-cyan-800 ${activeLink === 0 ? 'bg-cyan-800' : 'bg-cyan-600'
                                }`}
                            to="/alumni-corner/my-profile"
                            onClick={() => setActiveLink(0)}
                        >
                            My Profile
                        </Link>
                        <Link
                            className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 1 ? 'bg-cyan-800' : ''
                                }`}
                            to="/alumni-corner/my-batch-mates"
                            onClick={() => setActiveLink(1)}
                        >
                            My Batch Mates
                        </Link>
                        <Link
                            className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 2 ? 'bg-cyan-800' : ''
                                }`}
                            to="/alumni-corner/alumni-finder"
                            onClick={() => setActiveLink(2)}
                        >
                            Alumni Finder
                        </Link>
                        <Link
                            className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 3 ? 'bg-cyan-800' : ''
                                }`}
                            to="/alumni-corner/notification"
                            onClick={() => setActiveLink(3)}
                        >
                            Notifications
                        </Link> </div>
                </div>
                <div className='container mx-auto p-4 flex justify-between'>
                    {/* Left Section */}
                    <div className='lg:w-1/6 p-4 hidden lg:block'>
                        <div className='left-nav-menu flex flex-col'>
                            <Link
                                className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 0 ? 'bg-cyan-800' : 'bg-cyan-600'
                                    }`}
                                to="/alumni-corner/my-profile"
                                onClick={() => setActiveLink(0)}
                            >
                                My Profile
                            </Link>
                            <Link
                                className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 1 ? 'bg-cyan-800' : ''
                                    }`}
                                to="/alumni-corner/my-batch-mates"
                                onClick={() => setActiveLink(1)}
                            >
                                My Batch Mates
                            </Link>
                            <Link
                                className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 2 ? 'bg-cyan-800' : ''
                                    }`}
                                to="/alumni-corner/alumni-finder"
                                onClick={() => setActiveLink(2)}
                            >
                                Alumni Finder
                            </Link>
                            <Link
                                className={`p-2 text-center w-4/4 m-1 bg-cyan-600 text-sm text-white hover:bg-cyan-800 ${activeLink === 3 ? 'bg-cyan-800' : ''
                                    }`}
                                to="/alumni-corner/notification"
                                onClick={() => setActiveLink(3)}
                            >
                                Notifications
                            </Link>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className='lg:w-5/6 flex flex-col'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div >
    )
}
