import React from 'react'
import { EventsComponent } from '../components/events/events.component'
import TargetComponent from '../types/TargetComponent'

export const EventsLayout = () => {
    const targetComponent: TargetComponent = {
        componentName: "EVENTS", numberOfRecords: -1, showViewMoreButton: false,
    }
    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <EventsComponent {...targetComponent} />
        </div>
    )
}
