import React from 'react'
import TargetComponent from '../types/TargetComponent'
import { GalleryComponent } from '../components/gallery/gallery.component'

export const GalleryLayout = () => {
    const targetComponent: TargetComponent = {
        componentName: "GALLERY", numberOfRecords: -1, showViewMoreButton: false,
    }
    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className='container my-4 mx-auto px-4 md:px-12 bg-gray-100'>
                <GalleryComponent {...targetComponent} />
            </div>
        </div>
    )
}
