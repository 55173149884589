import React from 'react'
import { GalleryDetailsLayout } from '../layouts/gallery-details.layout'

export const GalleryDetailsPage = () => {
    return (
        <div>
            <GalleryDetailsLayout />
        </div>
    )
}
