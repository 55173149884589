import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
    Datepicker,
    Input,
    initTE,
} from 'tw-elements';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns'; // For formatting dates

import { enGB } from 'date-fns/locale'; // or the locale of your choice
import { getURL } from '../../api/URL';
import { getData, postData } from '../../api/AxisConfig';
import { Toast } from 'primereact/toast';
import { sourcePortalName } from '../../common/constant';
// Register the locale
registerLocale('en-GB', enGB);


export const RegisterComponent = () => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [response, setResponse] = useState("");
    const toast = useRef(null);
    const emptyForm = {
        firstName: '',
        lastName: '',
        email: '',
        passoutYear: null,
        dateOfBirth: '',
        mobileNumber: '',
        institution: '',
        jobDesignation: '',
        organization: '',
    }

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [insList, setInstList] = useState<any>(null);

    useEffect(() => {


        getData(getURL('GET_INSTITUTE', 'INSTITUTE', []), (resp: any) => {

            if (resp.status === 200 && resp.statusText === 'OK') {

                setInstList(resp.data.data)
                console.log(resp.data.data);
            }

        }, (err: any) => {
            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
            console.log(err);

        });

    }, []);

    const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        passoutYear: Yup.string().required('Passout Year is required'),
        dateOfBirth: Yup.string(),
        mobileNumber: Yup.string().required('Mobile Number is required'),
        institution: Yup.string().required('Please select institution'),
        jobDesignation: Yup.string(),
        organization: Yup.string(),
    });

    const formik = useFormik({
        initialValues: emptyForm,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setIsFormSubmitted(true);
            const formData = new FormData();
            formData.append('attachment_path', selectedFile == null ? "" : selectedFile);
            formData.append('fname', values["firstName"]);
            formData.append('lname', values["lastName"]);
            formData.append('email', values["email"]);
            formData.append('mob_no', values["mobileNumber"]);
            formData.append('dob', values["dateOfBirth"]);
            formData.append('passout_year', (values["passoutYear"] == null) ? "" : values["passoutYear"]);
            formData.append('job_designation', values["jobDesignation"]);
            formData.append('organization', values["organization"]);
            formData.append('source_portal_name', sourcePortalName());
            formData.append('institution_id', values["institution"]);
            formData.append('created_by', "999999");
            formData.append('is_active', 'Y');

            postData(getURL('CREATE', 'ALUMNI_REGISTER', [], true), formData, (resp: any) => {

                if (resp.status === 200 && resp.statusText === 'OK') {

                    //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Alumni Registered', life: 3000 });
                    setResponse("SUCCESSFUL");
                }

            }, (err: any) => {
                //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                console.log(err);
                setResponse("ERROR");
            });

            const fileInput = document.getElementById("formFileSm") as HTMLInputElement;
            if (fileInput) {
                fileInput.value = ''; // Reset the input value to empty
            }


            setTimeout(() => {
                setIsFormSubmitted(false);
                setResponse("");
            }, 5000);

            formik.resetForm();
        },
    });



    return (
        <div className="register-form">
            {isFormSubmitted && response == 'SUCCESSFUL' ? <div className='text-center bg-green-700 text-white  p-4 m-3'>Alumni registration form submitted</div> : (isFormSubmitted && response == 'ERROR') ? <div className='text-center bg-red-700 text-white font-semibold p-4 m-3'>Error while alumni registration</div> : ''}

            <div className="py-2 mx-auto bg-white p-4 shadow">
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-row">
                        <div className="w-1/2 pr-2">
                            <label htmlFor="firstName" className="block my-2 text-left text-sm font-medium text-gray-900">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.firstName && formik.touched.firstName ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter First Name"
                                {...formik.getFieldProps('firstName')}
                            //value={registerForm.firstName}

                            />
                            {formik.errors.firstName && formik.touched.firstName && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.firstName}</div>
                            )}
                        </div>
                        <div className="w-1/2 pl-2">
                            <label htmlFor="lastName" className="block my-2 text-left text-sm font-medium text-gray-900">
                                Last Name
                            </label>
                            <input
                                type="text"

                                id="lastName"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.lastName && formik.touched.lastName ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Last Name"
                                {...formik.getFieldProps('lastName')}
                            //value={registerForm.lastName}
                            />
                            {formik.errors.lastName && formik.touched.lastName && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.lastName}</div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2 pr-2">
                            <label htmlFor="firstName" className="block my-2 text-left text-sm font-medium text-gray-900">
                                Your email
                            </label>
                            <input
                                type="text"
                                id="email"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.email && formik.touched.email ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Your Email"
                                {...formik.getFieldProps('email')}
                            //value={registerForm.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                            )}
                        </div>
                        <div className="w-1/2 pl-2">
                            <label htmlFor="lastName" className="block my-2 text-left text-sm font-medium text-gray-900">
                                Passout Year
                            </label>


                            <DatePicker
                                id="passoutYear"
                                name="passoutYear"
                                maxDate={new Date()}

                                showYearDropdown  // Display the year dropdown
                                scrollableYearDropdown  // Make the year dropdown scrollable for a long year list
                                yearDropdownItemNumber={200} // Specify the number of years displayed in the dropdown
                                onChange={(date: Date) => formik.setFieldValue('passoutYear', date)}
                                dateFormat="dd/MM/yyyy" // Format the date as dd/mm/yyyy
                                locale="en-GB" // Set the locale
                                selected={formik.values.passoutYear}
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w- p-2.5 ${formik.errors.passoutYear && formik.touched.passoutYear ? 'border-red-500' : ''}`}


                                placeholderText="Select Passout Year"
                            />
                            {formik.errors.passoutYear && formik.touched.passoutYear && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.passoutYear}</div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row">

                        <div className="w-1/2 pr-2 ">
                            <label htmlFor="firstName"
                                className="block my-2 text-left text-sm font-medium text-gray-900">
                                Mobile Number
                            </label>
                            <input
                                {...formik.getFieldProps('mobileNumber')}
                                type="tel"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.mobileNumber && formik.touched.mobileNumber ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Mobile Number"
                            //value={registerForm.mobileNumber}
                            />
                            {formik.errors.mobileNumber && formik.touched.mobileNumber && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.mobileNumber}</div>
                            )}
                        </div>
                        <div className='w-1/2 pr-2 '>
                            <label htmlFor="institution"
                                className="block my-2 text-left text-sm font-medium text-gray-900">
                                Institution Name
                            </label>

                            <select className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.institution && formik.touched.institution ? 'border-red-500' : ''
                                }`}
                                {...formik.getFieldProps('institution')}
                            >
                                <option value="">Select</option>
                                {insList?.map((item: any) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}


                            </select>
                            {formik.errors.institution && formik.touched.institution && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.institution}</div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row">

                        <div className="w-1/2 pr-2 ">
                            <label htmlFor="firstName"
                                className="block my-2 text-left 
                      text-sm font-medium text-gray-900">
                                Job Designation
                            </label>
                            <input type="tel"
                                className="shadow-sm bg-gray-50 border
                      border-gray-300 text-gray-900 
                      text-sm rounded-lg block w-full p-2.5"
                                placeholder="Job Organization"
                                //value={registerForm.jobDesignation}
                                {...formik.getFieldProps('jobDesignation')}
                            />
                        </div>
                        <div className='w-1/2 pr-2 '>
                            <label htmlFor="institution"
                                className="block my-2 text-left text-sm 
                  font-medium text-gray-900">
                                Organization
                            </label>

                            <input type="text"
                                className="shadow-sm bg-gray-50 border
                      border-gray-300 text-gray-900 
                      text-sm rounded-lg block w-full p-2.5"
                                placeholder="Organization"
                                //value={registerForm.organization}
                                {...formik.getFieldProps('organization')}
                            />

                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="formFileSm"
                            className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">Documents</label>
                        <input
                            className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                            id="formFileSm"
                            type="file" onChange={onFileSelect}
                            accept="image/*" />
                    </div>

                    <div className="flex flex-1 mt-4 items-center text-right">
                        <button
                            type="submit"
                            className="p-2 text-right text-white border-green-600 bg-green-600 hover:scale-105 items-end"
                            disabled={formik.isSubmitting}
                        >
                            Register
                        </button>
                    </div>
                </form>
            </div >
        </div >
    );
};
