import React from 'react'

export const FooterComponent = () => {
    return (

        <footer className=" shado bg-gray-900 ">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0">
                        <img src='http://vves.org.in/static/media/vves_logo.9919bfbab96ffbc57094.png' className="h-8 mr-3" />
                        <span className="self-center text-sm font-semibold whitespace-nowrap text-white"> ALUMNI PORTAL</span>
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium sm:mb-0 text-gray-400">
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6 ">Home</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6">Events</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6 ">Gallery</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6 ">Opportunities</a>
                        </li>
                        <li>
                            <a href="#" className="mr-4 hover:underline md:mr-6 ">Contact us</a>
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-autoborder-gray-700 lg:my-8" />
                <span className="block text-sm text-gray-500 sm:text-centertext-gray-400">© 2023 <a href="#" className="hover:underline">Alumni Porral</a>. All Rights Reserved.</span>
            </div>
        </footer>


    )
}
