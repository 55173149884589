import React from 'react'
import EventDTO from '../../../types/EventsDTO'
import { FaCalendar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format, parse } from 'date-fns';

export const EvenItemComponent = (eventDTO: EventDTO) => {

  const formatPattern = 'dd/MM/yyyy HH:mm';

  const endDate = eventDTO.eventEnd != null ? parse(eventDTO.eventEnd, formatPattern, new Date()) : parse(eventDTO.eventStart, formatPattern, new Date());

  const currentDate = new Date();
  const dt = new Date(endDate);

  const differenceInMilliseconds = currentDate.getTime() - dt.getTime();

  return (

    <Link to={`/events/` + eventDTO.eventId} className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4 ">


      <article className="overflow-hidden rounded-lg shadow-lg bg-white">

        <a href="#">
          <img alt="Placeholder" className="block h-auto w-full" src={eventDTO.eventFile ? eventDTO.eventFile : 'http://vves.org.in/static/media/vves_logo.9919bfbab96ffbc57094.png'} />
        </a>

        <header className="flex items-center justify-between leading-tight p-2 md:p-4">
          <h1 className="text-base">
            <a className="no-underline hover:underline text-black" href="#">
              {eventDTO.eventTitle}
            </a>
          </h1>
        </header>

        <footer className="flex items-center justify-between leading-none p-2 md:p-4">
          <div className='flex flex-col'>
            {differenceInMilliseconds > 0 ? <p className='p-2 bg-cyan-600 text-white text-sm  text-center'>Past Event</p> : <p className='p-2 bg-orange-600 text-white text-sm  text-center'>Upcoming Event</p>}
            <div className='flex flex-row mt-6 space-x-2 justify-between'>
              <FaCalendar className='text-cyan-600 ' />
              <p className='text-xs'>
                {eventDTO.eventStart}
              </p>
              {eventDTO?.eventEnd ? <>
                <FaCalendar className='text-cyan-600 ' />
                <p className='text-xs'>
                  {eventDTO?.eventEnd}
                </p>
              </> : ''}
            </div>
            <Link to={`/events/` + eventDTO.eventId} className='mt-6 p-2 bg-green-700 text-white text-sm text-center hover:cursor-pointer'>View Event</Link>
          </div>
        </footer>

      </article>


    </Link>
  )
}
