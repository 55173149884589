import './App.css';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomePage } from './pages/home.pages';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import { Outlet } from 'react-router-dom';
import { AppRouter } from './router/app-router.router';

function App() {
  return (
    <div className='flex flex-col'>
      <HeaderComponent />
      <Outlet />
      <FooterComponent />
    </div>
  );
}

export default App;
