import React from 'react'
import { AlumniFinderLayout } from '../layouts/alumni-finder.layout'

export const AlumniFinderPage = () => {
    return (
        <div>
            <AlumniFinderLayout />
        </div>
    )
}
