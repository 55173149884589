import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import GalleryDTO from '../types/GalleryDTO';
import { GalleryDetailsItemComponent } from '../components/gallery-details-item/gallery-details-item.component';
import { SectionHeaderComponent } from '../components/section-header/section-header.component';
import { getURL } from '../api/URL';
import { sourcePortalName } from '../common/constant';
import { getData } from '../api/AxisConfig';
import parse from 'html-react-parser';

export const GalleryDetailsLayout = () => {

    const { id } = useParams();

    const [galleryDetails, setGalleryDetails] = useState<GalleryDTO>();
    const isPageLoaded = useRef(false);
    useEffect(() => {

        if (isPageLoaded.current == true) return;
        fetchData();
        isPageLoaded.current = true;

    }, [id]);




    const fetchData = async () => {



        getData(getURL('GET', 'ALUMNI_GALLERY', [sourcePortalName(), id], true), (resp: any) => {
            if (resp.status === 200 && resp.statusText === 'OK') {


                const finalResp = resp.data.data;

                let galleryDTO: GalleryDTO = {
                    id: id,
                    createdAt: finalResp.created_at,
                    title: finalResp.title,
                    description: finalResp.body,
                    photos: []
                }

                getData(getURL('GET', 'ALUMNI_GALLERY_PHOTO', [sourcePortalName(), id], true), (resp2: any) => {
                    if (resp2.status === 200 && resp2.statusText === 'OK') {

                        resp2.data.data.map((item: any) => {
                            galleryDTO.photos.push(item["image_path"]);
                        })

                        setGalleryDetails(galleryDTO);
                    }
                }, (err: any) => {
                    //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                    console.log(err);
                });



            }
        }, (err: any) => {
            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
            console.log(err);
        });




    }


    if (galleryDetails == null) {
        return <>No Data Available</>
    }

    return (

        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className=" bg-white shadow flex flex-col">
                <div>
                    <SectionHeaderComponent title='Gallery Details' navigationPath='' showViewMoreButton={false} />
                </div>
                <div className=''>
                    <p className=' text-2xl text-black font-semibold   p-4 text-center'>{galleryDetails?.title}</p>
                </div>
                <div className='ml-4 mr-4'>
                    <p className=' text-sm text-gray-600 font-semibold   p-4 text-center'>{galleryDetails?.description ? parse(galleryDetails?.description) : ''}</p>
                </div>
                <div>
                    <div className="container mx-auto px-4 md:px-12">
                        <div className="flex flex-wrap -mx-1 lg:-mx-4">
                            {

                                galleryDetails?.photos.map((item: any, index: number) => {
                                    const image: string = item;
                                    return <GalleryDetailsItemComponent image={image} key={index} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
