import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { sourcePortalName } from '../common/constant';
import { getURL } from '../api/URL';
import { postData, getData } from '../api/AxisConfig';

export const ContactUsLayout = () => {


    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [response, setResponse] = useState("");

    const emptyForm = {
        firstName: '',
        lastName: '',
        email: '',
        institution: '',
        subject: '',
        message: '',
    }

    const [insList, setInstList] = useState<any>(null);

    useEffect(() => {


        getData(getURL('GET_INSTITUTE', 'INSTITUTE', []), (resp: any) => {

            if (resp.status === 200 && resp.statusText === 'OK') {

                setInstList(resp.data.data)
                console.log(resp.data.data);
            }

        }, (err: any) => {
            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
            console.log(err);

        });

    }, []);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        institution: Yup.string().required('Please select institution'),
        subject: Yup.string().required('Please provide a Subject'),
        message: Yup.string().required('Please provide message'),
    });

    const formik = useFormik({
        initialValues: emptyForm,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setIsFormSubmitted(true);
            const formData = new FormData();
            formData.append('fname', values["firstName"]);
            formData.append('lname', values["lastName"]);
            formData.append('email', values["email"]);
            formData.append('subject', values["subject"]);
            formData.append('message', values["message"]);
            formData.append('source_portal_name', sourcePortalName());
            formData.append('institution_id', values["institution"]);
            formData.append('created_by', "999999");
            formData.append('is_active', 'Y');





            postData(getURL('CREATE', 'CONTACTUS', [], true), formData, (resp: any) => {

                if (resp.status === 200 && resp.statusText === 'OK') {

                    //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Alumni Registered', life: 3000 });
                    console.log("created");
                    setResponse("SUCCESSFUL");
                }

            }, (err: any) => {
                //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                console.log(err);
                setResponse("ERROR");
            });

            setTimeout(() => {
                setIsFormSubmitted(false);
                setResponse("");
            }, 5000);

            formik.resetForm();
        },
    });



    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className="py-2 px-4 mx-auto max-w-screen-md bg-white p-4 shadow">
                <h2 className="mb-4
                           text-center text-gray-900 font-semibold text-xl  border-b-4 border-b-teal-600 p-2 uppercase">
                    Contact Us
                </h2>
                <p className="mb-4 font-light text-center 
                          text-gray-500 sm:text-base">
                    Got a issue? Want to send feedback?
                    Need details about our Courses? Let us know.
                </p>
                {isFormSubmitted && response == 'SUCCESSFUL' ? <div className='text-center bg-green-700 text-white  p-4 m-3'>Query Registered successfully</div> : (isFormSubmitted && response == 'ERROR') ? <div className='text-center bg-red-700 text-white font-semibold p-4 m-3'>Error While Raising Query</div> : ''}

                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-row">
                        <div className="w-1/2 pr-2 ">
                            <label htmlFor="firstName"
                                className="block my-2 text-left 
                                          text-sm font-medium text-gray-900">
                                First Name
                            </label>
                            <input type="text"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.firstName && formik.touched.firstName ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter First Name"
                                {...formik.getFieldProps('firstName')} />
                            {formik.errors.firstName && formik.touched.firstName && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.firstName}</div>
                            )}
                        </div>
                        <div className="w-1/2 pl-2">
                            <label htmlFor="firstName"
                                className="block my-2 text-left text-sm 
                                          font-medium text-gray-900">
                                Last Name
                            </label>
                            <input type="text"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.lastName && formik.touched.lastName ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Last Name"
                                {...formik.getFieldProps('lastName')}
                            />

                            {formik.errors.lastName && formik.touched.lastName && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.lastName}</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email"
                            className="block my-2 text-left text-sm 
                                      font-medium text-gray-900">
                            Your email
                        </label>
                        <input type="email"
                            className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.email && formik.touched.email ? 'border-red-500' : ''
                                }`}
                            placeholder="example@example.com"
                            {...formik.getFieldProps('email')} />
                        {formik.errors.email && formik.touched.email && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        )}
                    </div>
                    <div>
                        <label htmlFor="subject"
                            className="block my-2 text-left 
                                      text-sm font-medium text-gray-900">
                            Subject
                        </label>
                        <input type="text"
                            className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.subject && formik.touched.subject ? 'border-red-500' : ''
                                }`}
                            placeholder="What issue/suggestion do you have?"
                            {...formik.getFieldProps('subject')} />
                        {formik.errors.subject && formik.touched.subject && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.subject}</div>
                        )}
                    </div>
                    <div>
                        <label htmlFor="subject"
                            className="block my-2 text-left 
                                      text-sm font-medium text-gray-900">
                            Institution
                        </label>

                        <select className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.institution && formik.touched.institution ? 'border-red-500' : ''
                            }`}
                            {...formik.getFieldProps('institution')}
                        >
                            <option value="">Select</option>
                            {insList?.map((item: any) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}

                        </select>
                        {formik.errors.institution && formik.touched.institution && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.institution}</div>
                        )}

                    </div>
                    <div >
                        <label htmlFor="message"
                            className="block my-2 text-left 
                                      text-sm font-medium text-gray-900 ">
                            Your message
                        </label>
                        <textarea
                            className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.message && formik.touched.message ? 'border-red-500' : ''
                                }`}
                            placeholder="Query/Suggestion..."
                            {...formik.getFieldProps('message')}
                        />
                        {formik.errors.message && formik.touched.message && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
                        )}

                    </div>
                    <div className='flex flex-1 mt-4 items-center'>
                        <button
                            type="submit"
                            className="p-2 text-right text-white border-green-600 bg-green-600 hover:scale-105 items-end"
                            disabled={formik.isSubmitting}
                        >
                            Send Message
                        </button>
                    </div>
                </form>
            </div >

        </div >
    )
}
