import React from 'react'
import { ImageSliderComponent } from '../components/image-slider/image-slider.component'
import { EventsComponent } from '../components/events/events.component'
import { GalleryComponent } from '../components/gallery/gallery.component'
import { FaGraduationCap, FaBookReader, FaUserCircle } from 'react-icons/fa';
import { HomeStaticComponent } from '../components/home-static/home-static.component';
import TargetComponent from '../types/TargetComponent';

export const HomeLayout = () => {

    const targetComponent: TargetComponent = {
        componentName: "HOME", numberOfRecords: 4, showViewMoreButton: true,
    }

    return (
        <div>
            <ImageSliderComponent />
            <HomeStaticComponent />
            <GalleryComponent  {...targetComponent} numberOfRecords={3} />
            <EventsComponent {...targetComponent} />
        </div>
    )
}
