import React from 'react'
import { EventDetailsLayout } from '../layouts/event-details.layout'

export const EventDetailsPage = () => {
    return (
        <div>
            <EventDetailsLayout />
        </div>
    )
}
