import { Route, Routes } from 'react-router-dom'
import { HomePage } from '../pages/home.pages'
import { ContactUsPage } from '../pages/contactus.page'
import { EventsPage } from '../pages/events.page'
import { EventDetailsPage } from '../pages/event-details.page'
import { GalleryDetailsPage } from '../pages/gallery-details.page'
import { GalleryPage } from '../pages/gallery.page'
import { RegisterLoginPage } from '../pages/register-login.page'
import { AlumniCornerPage } from '../pages/alumni-corner.page'
import { MyprofilePage } from '../pages/my-profile.page'
import { MyBatchMatesPage } from '../pages/my-batch-mates.page'
import { AlumniFinderPage } from '../pages/alumni-finder.page'
import { AlumniProfilePage } from '../pages/alumni-profile.page'
import { AlumniCornerNotificationPage } from '../pages/alumni-corner-notification.page'
import { isAuthenticated } from '../common/auth-constant'

export const AppRouter = () => {
    return (
        <Routes>

            <Route path='/' element={<HomePage />} />
            <Route path='/events' element={<EventsPage />} />
            <Route path='/events/:id' element={<EventDetailsPage />} />
            <Route path='/gallery' element={<GalleryPage />} />
            <Route path='/gallery/:id' element={<GalleryDetailsPage />} />
            <Route path='/contactus' element={<ContactUsPage />} />
            <Route path='/register-login' element={<RegisterLoginPage />} />
            {isAuthenticated() === 'true' ?
                <Route path='/alumni-corner' element={<AlumniCornerPage />} >
                    <Route path='/alumni-corner/my-profile' element={<MyprofilePage />} />
                    <Route path='/alumni-corner/my-batch-mates' element={<MyBatchMatesPage />} />
                    <Route path='/alumni-corner/alumni-finder' element={<AlumniFinderPage />} />
                    <Route path='/alumni-corner/alumni-profile/:id' element={<AlumniProfilePage />} />
                    <Route path='/alumni-corner/notification' element={<AlumniCornerNotificationPage />} />
                </Route> :
                <Route></Route>
            }

        </Routes>
    )
}
