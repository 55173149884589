import React from 'react'
import { EventsLayout } from '../layouts/events.layout'

export const EventsPage = () => {
    return (
        <div>
            <EventsLayout />
        </div>
    )
}
