import React from 'react'
import { ContactUsLayout } from '../layouts/contactus.layout'

export const ContactUsPage = () => {
    return (
        <div className='flex flex-col'>
            <ContactUsLayout />
        </div>

    )
}
