import { useNavigate } from 'react-router-dom';
import { AlumniCornerLayout } from '../layouts/alumni-corner.layout'
import { useEffect } from 'react';

export const AlumniCornerPage = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if (window.location.pathname === '/alumni-corner') {
            navigate('/alumni-corner/my-profile');
        }
    });

    return (
        <div>
            <AlumniCornerLayout />
        </div>
    )
}
