import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppRouter } from '../../router/app-router.router';
import { isAuthenticated, logout, setIsAuthenticated } from '../../common/auth-constant';

export const HeaderComponent = () => {

    const navigate = useNavigate();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <nav className="bg-cyan-800 shadow uppercase text-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0 text-white items-center flex flex-row">
                                <img src='http://vves.org.in/static/media/vves_logo.9919bfbab96ffbc57094.png' className="h-12 w-14 " /><span className='text-white text-lg font-semibold'> ALUMNI PORTAL</span>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <Link to="/" className="text-white hover:text-gray-300 p-2">Home</Link>
                                <Link to="/events" className="text-white hover:text-gray-300 p-2">Events</Link>
                                {/*<Link to="" className="text-white hover:text-gray-300 p-2">Alumni Finder</Link>*/}
                                <Link to="/gallery" className="text-white hover:text-gray-300 p-2">Gallery</Link>
                                {/*<Link to="" className="text-white hover:text-gray-300 p-2">Opportunities</Link>*/}
                                <Link to="/contactus" className="text-white hover:text-gray-300 p-2">Contact Us</Link>
                                {isAuthenticated() == 'true' ? <Link to="/alumni-corner" className="text-white hover:text-gray-300 p-2 borde">Alumni Corner</Link> : <> </>}
                                {isAuthenticated() == 'true' ? <button className="text-white hover:text-gray-300 p-2 bg-yellow-600" onClick={() => {
                                    setIsAuthenticated(false);
                                    logout();
                                    navigate("/");
                                }}>Logout</button> : <></>}
                                {isAuthenticated() == 'false' ? <Link to="/register-login" className="text-white hover:text-gray-300 p-2 bg-yellow-600">Login/Register</Link> : <></>}
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                onClick={toggleMobileMenu}
                                className="text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
                            >
                                <svg
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    {isMobileMenuOpen ? (
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    ) : (
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    )}
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {isMobileMenuOpen && (
                    <div className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            <Link to="/" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Home</Link>
                            <Link to="/events" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Events</Link>
                            {/* <Link to="" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Alumni Finder</Link>*/}
                            <Link to="/gallery" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Gallery</Link>
                            {/*<Link to="" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Opportunities</Link>*/}
                            <Link to="/contactus" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Contact Us</Link>
                            {isAuthenticated() == 'true' ? <Link to="/alumni-corner" className="text-white hover:text-gray-300 block p-2 text-start ml-4">Alumni Corner</Link> : <></>}
                            {isAuthenticated() == 'true' ? <button className="text-white hover:text-gray-300 block p-2 ml-4 bg-yellow-600 text-center" onClick={() => {
                                setIsAuthenticated(false);
                                navigate("/");
                            }}>Logout</button> : <></>}
                            {isAuthenticated() == 'false' ? <Link to="/register-login" className="text-white hover:text-gray-300 block p-2 ml-4 bg-yellow-600 text-center">Login/Register</Link> : <></>}

                        </div>
                    </div>
                )}
            </nav>
            <AppRouter />
        </>
    );
};

