import React from 'react'
import { MyBatchMatesLayout } from '../layouts/my-batch-mates.layout'

export const MyBatchMatesPage = () => {
    return (
        <div>
            <MyBatchMatesLayout />
        </div>
    )
}
