import React from 'react'
import BatchMate from '../../types/BatchMateDTO'
import { Link } from 'react-router-dom'
import { format } from 'date-fns';

export const BatchMateItemComponent = (item: BatchMate) => {


    return (
        <Link to={'/alumni-corner/alumni-profile/' + item.profile_id}
            key={item.profile_id}
            className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-4"
        >
            {/* Profile Card */}
            <div className="bg-white shadow rounded-lg overflow-hidden text-center">
                <img
                    src={item?.profile_pic == '' ? process.env.PUBLIC_URL + '/avatar.jpg' : item?.profile_pic}
                    alt="Profile Picture"
                    className="w-36 h-36 object-cover object-center mx-auto shadow-lg items-center rounded-full mt-2"
                />
                <div className="p-4">
                    <h2 className="text-base font-semibold">
                        {item.fname} {item.lname}
                    </h2>
                    <p className="text-gray-600 text-sm">
                        Passing Year:{format(new Date(item["passout_year"] + ""), 'yyyy')}
                    </p>
                </div>
            </div>
        </Link>
    )
}
