import React, { useEffect, useState } from 'react'
import AlumniNotification from '../types/AlumniNotification'
import { AlumniCornerHeaderComponent } from '../components/alumni-corner-header/alumni-corner-header.component'
import { getData } from '../api/AxisConfig';
import { getURL } from '../api/URL';
import { sourcePortalName } from '../common/constant';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import { Image } from 'primereact/image';
export const AlumniCornerNotificationLayout = () => {


    const [notification, setNotification] = useState<AlumniNotification[]>();

    useEffect(() => {
        getData(getURL("GET", "ALUMNI_NOTIFICATION", [sourcePortalName()], true),
            (resp: any) => {
                console.log(resp);
                if (resp.status === 200 && resp.statusText === 'OK') {
                    setNotification(resp.data.data);
                }

            },
            (err: any) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <AlumniCornerHeaderComponent title='Notifications' />

            {
                notification?.map(notification => {
                    return <div className="p-3 bg-white m-4 shadow-lg">
                        <div className='ml-4'>
                            <p className='text-sm font-base text-gray-500 lg:mt-0 mt-4'>{format(new Date(notification.created_at + ""), 'dd-MM-yyyy')}</p>
                            <p className='text-base font-medium  mt-4'>{notification.title}</p>
                            <p className='text-sm text-gray-600 mt-4'>{parse(notification.body)}</p>
                            <div className='tags flex flex-row space-x-2'>
                                <Image src={notification.attachment_path} preview zoomSrc={notification.attachment_path} height='100' width='100' />
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    )
}
