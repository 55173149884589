import React from 'react'
import GalleryDTO from '../../../types/GalleryDTO'
import { Link } from 'react-router-dom'
import { Image } from 'primereact/image';
import { format } from 'date-fns';
export const GalleryItemComponent = (galleryItem: GalleryDTO) => {

    const formattedDate = format(new Date(galleryItem.createdAt + ""), 'dd MMM yyyy');

    return (

        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 ">
            <Link to={`/gallery/` + galleryItem.id}>
                <article className="overflow-hidden rounded-lg shadow-lg bg-white">
                    <a href="#">
                        <Image alt="Placeholder" className="block h-auto w-full" src={galleryItem.photos[0]} zoomSrc={galleryItem.photos[0]} preview />
                    </a>
                    <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-base">
                            <a className="no-underline hover:underline text-black" href="#">
                                {galleryItem.title}
                            </a>
                        </h1>
                        <p className="text-gray-600 text-xs">
                            {formattedDate}
                        </p>
                    </header>
                </article>
            </Link>
        </div >

    )
}
