import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import EventDTO from '../types/EventsDTO';
import { FaCalendar, FaEye } from 'react-icons/fa';
import { SectionHeaderComponent } from '../components/section-header/section-header.component';
import { sourcePortalName } from '../common/constant';
import { getURL } from '../api/URL';
import { getData } from '../api/AxisConfig';
import parse from 'html-react-parser';

export const EventDetailsLayout = () => {

    const { id } = useParams();

    const [eventDetails, setEventDetails] = useState<EventDTO>();
    const isPageLoaded = useRef(false);

    const fetchData = () => {
        getData(getURL('GET', 'ALUMNI_EVENT', [sourcePortalName(), id], true), (resp: any) => {
            if (resp.status === 200 && resp.statusText === 'OK') {

                const finalResp = resp.data.data;
                console.log(finalResp);

                const eventDTO: EventDTO = {
                    eventId: id,
                    eventStart: finalResp.start_dt,
                    eventEnd: finalResp.end_dt,
                    eventTitle: finalResp.title,
                    eventFile: finalResp.attachment_path,
                    discription: finalResp.body,
                    registerLinks: finalResp.registration_link
                }

                setEventDetails(eventDTO);
            }
        }, (err: any) => {
            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
            console.log(err);
        });
    }


    useEffect(() => {

        if (isPageLoaded.current == true) return;
        fetchData();
        isPageLoaded.current = true;

    }, [id]);





    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className=" bg-white shadow flex flex-col">
                <div>
                    <SectionHeaderComponent title='Event Details' navigationPath='' showViewMoreButton={false} />
                </div>
                <div className="p-4 shadow flex md:flex-row flex-col">
                    <img src={eventDetails?.eventFile ? eventDetails.eventFile : 'http://vves.org.in/static/media/vves_logo.9919bfbab96ffbc57094.png'} className='md:w-1/3 shadow m-2 hide' />
                    <div className='flex flex-col m-2 md:w-2/3'>
                        <div className='text-base text-white font-semibold bg-gray-800  p-4'>
                            <div className=''>{eventDetails?.eventTitle}</div>
                            <div className='flex flex-row mt-6 space-x-2 items-center text-gray-500 font-semibold'>
                                <FaCalendar className='text-cyan-600 ' />
                                <p className='text-xs'>
                                    Start: {eventDetails?.eventStart}
                                </p>
                                {eventDetails?.eventEnd ? <>
                                    <div>-</div>
                                    <FaCalendar className='text-cyan-600 ' />
                                    <p className='text-xs'>
                                        End: {eventDetails?.eventEnd}
                                    </p> </> : ''}

                                {/* <FaEye className='text-cyan-600 ' />
                                 <p className='text-xs'>
                                    {eventDetails?.noOfViews}
                                </p>*/}
                            </div>
                        </div>
                        <div className='text-base text-black mt-4 underline font-semibold'>Description</div>
                        <div className='text-base text-gray-700 mt-4'>{eventDetails?.discription ? parse(eventDetails?.discription) : ''}</div>
                        <div className='text-right'>
                            {eventDetails?.registerLinks ?
                                <a href={eventDetails?.registerLinks} className='text-base text-white mt-4 bg-green-600 p-2 w-fit hover:bg-green-900  shadow'>Register Now</a> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
