import React from 'react'
import { GalleryLayout } from '../layouts/gallery.layout'

export const GalleryPage = () => {
    return (
        <div>
            <GalleryLayout />
        </div>
    )
}
