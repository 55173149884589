import React from 'react'
import SectionHeader from '../../types/SectionHeaderInt'
import { Link } from 'react-router-dom'

export const SectionHeaderComponent = (sectionHeader: SectionHeader) => {

    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className='flex flex-row items-center justify-between'>
                <div className='font-semibold text-xl  border-b-4 border-b-teal-600 p-2 uppercase'>{sectionHeader.title}</div>
                <div className={sectionHeader.showViewMoreButton ? 'visible ' : 'hidden'}>
                    <Link to={sectionHeader.navigationPath} className='border border-cyan-600 text-cyan-700 p-2 hover:bg-cyan-700 hover:text-white hover:font-semibold'>View More</Link>
                </div>
            </div>
        </div>
    )
}
