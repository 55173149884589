import React, { useEffect, useRef, useState } from 'react'

import { EvenItemComponent } from './event-item/event-item.component';
import EventDTO from '../../types/EventsDTO';
import { SectionHeaderComponent } from '../section-header/section-header.component';
import TargetComponent from '../../types/TargetComponent';
import { getData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL';
import { sourcePortalName } from '../../common/constant';

export const EventsComponent = (targetComponent: TargetComponent) => {

    const [events, setEvents] = useState<EventDTO[]>([]);
    const isPageLoaded = useRef(false);

    useEffect(() => {
        if (isPageLoaded.current) return;
        fetchData();
        isPageLoaded.current = true;
    }, [])

    const fetchData = () => {

        const events: EventDTO[] = [];
        getData(
            getURL("GET", "ALUMNI_EVENT", [sourcePortalName()], true), (resp: any) => {
                if (resp.status === 200 && resp.statusText === 'OK') {

                    resp.data.data.map((item: any) => {

                        const event: EventDTO = {
                            eventId: item["event_id"],
                            eventTitle: item["title"],
                            discription: item["body"],
                            eventStart: item["start_dt"],
                            eventEnd: item["end_dt"],
                            isFileType: item["attachment_type"],
                            eventFile: item["attachment_path"],
                            noOfViews: item["view_count"],
                            registerLinks: item["registration_link"]
                        }
                        events.push(event);
                    })

                    if (targetComponent.componentName == "HOME") {
                        setEvents(events.slice(0, targetComponent.numberOfRecords));
                    } else {
                        setEvents(events);
                    }

                }
            },
            (error: any) => {
                console.log(error);
            }
        );


    }

    return (
        <div className='container my-4 mx-auto px-4 md:px-12 bg-gray-100'>
            <div className='flex flex-col'>
                <SectionHeaderComponent title='Events' navigationPath='/events' showViewMoreButton={targetComponent.showViewMoreButton} />
                <div>
                    <div className="container mx-auto px-4 md:px-12">
                        <div className="flex flex-wrap -mx-1 lg:-mx-4">

                            {events.map(item => {
                                return <EvenItemComponent {...item} key={item.eventId} />
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
