import React from 'react'
import { MyProfileLayout } from '../layouts/my-profile.layout'

export const MyprofilePage = () => {
    return (
        <div>
            <MyProfileLayout />
        </div>
    )
}
