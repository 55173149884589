import React, { useState, useEffect, useRef } from 'react';
import { Galleria } from 'primereact/galleria';
import { getData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL';
import { sourcePortalName } from '../../common/constant';

export const ImageSliderComponent = () => {

    const [itemImages, setItemImages] = useState<{ itemImageSrc: string; alt: string; }[]>([]);
    const isPageLoaded = useRef(false);
    const imgs: { itemImageSrc: string; alt: string; }[] = [];
    useEffect(() => {
        if (isPageLoaded.current) return;
        fetchData();
        isPageLoaded.current = true;

    }, []);

    const fetchData = () => {
        getData(getURL('GET', 'ALUMNI_HOME_SLIDER', [sourcePortalName()], true), (resp: any) => {
            if (resp.status === 200 && resp.statusText === 'OK') {

                const finalResp = resp.data.data;

                finalResp.map((item: any) => {
                    const slider: { itemImageSrc: string; alt: string; } = {
                        itemImageSrc: item.image_path,
                        alt: item.caption
                    }

                    imgs.push(slider);
                });

                setItemImages(imgs);
            }
        }, (err: any) => {
            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
            console.log(err);
        });
    }

    const itemTemplate = (item: any) => {
        return (
            <div
                className='w-[100%] max-h-96 flex justify-center overflow-hidden m-4 shadow'>
                <img
                    src={item.itemImageSrc}
                    alt={item.alt}
                    style={{
                        // Center-crop effect
                        width: '100%'
                    }}
                />
            </div>
        );
    }

    const thumbnailTemplate = (item: any) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block', height: '20%' }} />;
    }


    return (
        <div className="container my-4 mx-auto px-4 md:px-12">
            <Galleria value={itemImages} numVisible={5} circular style={{ maxWidth: '100%' }}
                showItemNavigators showItemNavigatorsOnHover
                showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} autoPlay />
        </div>
    )
}
