import React from 'react'
import { RegisterLoginLayout } from '../layouts/register-login.layout'

export const RegisterLoginPage = () => {
    return (
        <div>
            <RegisterLoginLayout />
        </div>
    )
}
