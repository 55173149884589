import React from 'react'
import GalleryDTO from '../../types/GalleryDTO'
import { Image } from 'primereact/image';

export const GalleryDetailsItemComponent = (props: { image: string }) => {
    return (

        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 ">
            <article className="overflow-hidden rounded-lg shadow-lg bg-white">
                <a href="#">
                    <Image alt="Placeholder" className="block h-auto w-full" src={props.image} zoomSrc={props.image} preview />
                </a>
            </article>
        </div >

    )
}
