import React from 'react'
import { AlumniProfileLayout } from '../layouts/alumni-profile.layout'
import { useParams } from 'react-router-dom'

export const AlumniProfilePage = () => {
    const { id } = useParams();

    return (
        <div>
            <AlumniProfileLayout />
        </div>
    )
}
