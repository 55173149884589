import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AlumniCornerHeaderComponent } from '../components/alumni-corner-header/alumni-corner-header.component'
import { Image } from 'primereact/image'
import { getProfile, login, setIsAuthenticated } from '../common/auth-constant';
import UserProfile from '../types/Profile';
import { format } from 'date-fns';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { sourcePortalName } from '../common/constant';
import { postData, putData } from '../api/AxisConfig';
import { getURL } from '../api/URL';


export const MyProfileLayout = () => {

    const [profile, setProfile] = useState<UserProfile>();
    const [visible, setVisible] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);


    const [response, setResponse] = useState("");
    const toast = useRef(null);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    let emptyForm = {
        fname: '',
        lname: '',
        email: '',
        passoutYear: null,
        mobileNumber: '',
        institution: '',
        jobDesignation: '',
        organization: '',
        basic_information: '',
        linked_in_link: '',
        twitter_link: '',
        mob_no: ''
    }


    const validationSchema = Yup.object().shape({
        job_designation: Yup.string(),
        basic_information: Yup.string().required('Please provide little information about yourself'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        jobDesignation: Yup.string(),
        organization: Yup.string(),
        fb_link: Yup.string(),
        instagram_link: Yup.string(),
        linked_in_link: Yup.string(),
        twitter_link: Yup.string(),
        mob_no: Yup.string().required('Mobile number is required'),
    });

    useEffect(() => {

        const prof: any = getProfile();
        const userProfile = JSON.parse(prof);
        ;
        const tempProfile: UserProfile = {
            profile_id: userProfile["profile_id"],
            fname: userProfile["fname"],
            lname: userProfile["lname"],
            education: userProfile["education"],
            email: userProfile["email"],
            mob_no: userProfile["mob_no"],
            basic_information: userProfile["basic_information"],
            job_designation: userProfile["job_designation"],
            passout_year: format(new Date(userProfile["passout_year"] + ""), 'yyyy'),
            organization: userProfile["organization"],
            linked_in_link: userProfile["linked_in_link"],
            twitter_link: userProfile["twitter_link"],
            profile_pic: userProfile["profile_pic"]
        }



        setProfile(tempProfile);


        formik.setValues({
            fname: profile?.fname || '',
            lname: profile?.lname || '',
            email: profile?.email || '',
            basic_information: profile?.basic_information || '',
            jobDesignation: profile?.job_designation || '',
            mobileNumber: profile?.mob_no || '',
            organization: profile?.organization || '',
            linked_in_link: profile?.linked_in_link || '',
            twitter_link: profile?.twitter_link || '',
            mob_no: profile?.mob_no || '',
            passoutYear: null,
            institution: '',
        });




    }, [profile?.profile_id]);

    const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const formik = useFormik({
        initialValues: {
            fname: profile?.fname || '',
            lname: profile?.lname || '',
            email: profile?.email || '',
            basic_information: profile?.basic_information || '',
            jobDesignation: profile?.job_designation || '',
            mobileNumber: profile?.mob_no || '',
            organization: profile?.organization || '',
            linked_in_link: profile?.linked_in_link || '',
            twitter_link: profile?.twitter_link || '',
            mob_no: profile?.mob_no || '',
            passoutYear: null,
            institution: ''
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);

            const temp: any = JSON.parse(getProfile() + "");
            setIsFormSubmitted(true);
            const formData = new FormData();
            formData.append('attachment_path', selectedFile == null ? "" : selectedFile);
            formData.append('email', values.email);
            formData.append('mob_no', values.mob_no);
            formData.append('job_designation', values["jobDesignation"]);
            formData.append('organization', values["organization"]);
            formData.append('basic_information', values.basic_information);
            formData.append('linked_link', values.linked_in_link);
            formData.append('twitter_link', values.twitter_link);
            formData.append('profile_id', getProfile() != null ? temp["profile_id"] : "");
            formData.append('source_portal_name', sourcePortalName());
            formData.append('institution_id', values["institution"]);
            formData.append('created_by', "999999");
            formData.append('is_active', 'Y');


            putData(getURL('UPDATE', 'ALUMNI_LOGIN', [], true), formData, (resp: any) => {

                if (resp.status === 200 && resp.statusText === 'OK') {

                    //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Alumni Registered', life: 3000 });
                    setResponse("SUCCESSFUL");
                    const formData2 = new FormData();
                    formData2.append('id', profile?.profile_id + "");
                    formData2.append('token', "dummy");

                    postData(getURL('GET', 'USERS', [], true), formData2, (resp: any) => {

                        if (resp.status === 200 && resp.statusText === 'OK') {
                            login(resp.data.data);
                            setIsAuthenticated(true);
                            window.location.reload();
                        }
                    },
                        (err: any) => {
                            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                            console.log(err);
                            setResponse("ERROR");
                        }
                    );

                }

            }, (err: any) => {
                //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                console.log(err);
                setResponse("ERROR");
            });

            const fileInput = document.getElementById("formFileSm") as HTMLInputElement;
            if (fileInput) {
                fileInput.value = ''; // Reset the input value to empty
            }


            setTimeout(() => {
                setIsFormSubmitted(false);
                setResponse("");
            }, 5000);

            formik.resetForm();
        },
    });


    return (
        <div className='flex flex-col'>
            <AlumniCornerHeaderComponent title='My Profile' />
            <div className='flex flex-row'>

                <div className="bg-gray-100 min-h-screen p-4 w-[100%]">
                    <div className="bg-white p-4 rounded-lg shadow-md mx-auto">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-4">
                                <Image
                                    src={profile?.profile_pic == '' ? process.env.PUBLIC_URL + '/avatar.jpg' : profile?.profile_pic}
                                    preview
                                    zoomSrc={profile?.profile_pic == '' ? process.env.PUBLIC_URL + '/avatar.jpg' : profile?.profile_pic}
                                    alt="Profile Picture"
                                    className="w-20 h-20 rounded-full"
                                />
                                <div>
                                    <h1 className="text-xl md:text-2xl font-semibold">{profile?.fname + " " + profile?.lname}</h1>
                                    <p className="text-sm md:text-base text-gray-600">{profile?.job_designation}</p>
                                </div>
                            </div>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded-full hidden lg:block " onClick={() => setVisible(true)}>
                                Edit
                            </button>
                        </div>
                        <div className='flex flex-row lg:hidden mt-4'>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded-full " onClick={() => setVisible(true)}>
                                Edit
                            </button>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">About</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                {profile?.basic_information}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Education</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                {profile?.education}
                                <br />
                                Passing Year  <b> {profile?.passout_year}</b>
                            </p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Work</h2>
                            <p className="text-sm md:text-base text-gray-600">{profile?.organization}</p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Contact Information</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                Email: {profile?.email}
                                <br />
                                Phone: {profile?.mob_no}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Social Media</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                LinkedIn: <h6>{profile?.linked_in_link}</h6>
                                <br />
                                Twitter: <h6>{profile?.twitter_link}</h6>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header="Update Information" visible={visible} onHide={() => setVisible(false)}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                {isFormSubmitted && response == 'SUCCESSFUL' ? <div className='text-center bg-green-700 text-white  p-4 m-3'>Profile Updated</div> : (isFormSubmitted && response == 'ERROR') ? <div className='text-center bg-red-700 text-white font-semibold p-4 m-3'>Error while updating profile..</div> : ''}
                <form onSubmit={formik.handleSubmit}>
                    <div >
                        <div className='flex-col'>
                            <label htmlFor="firstName" className="block my-2 text-left text-sm font-medium text-gray-900">
                                About Yourself
                            </label>
                            <textarea

                                id="aboutUs"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.basic_information && formik.touched.basic_information ? 'border-red-500' : ''
                                    }`}
                                placeholder="About Yourself"
                                {...formik.getFieldProps('basic_information')}
                            //value={registerForm.firstName}

                            ></textarea>
                            {formik.errors.basic_information && formik.touched.basic_information && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.basic_information}</div>
                            )}

                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2 pr-2">
                            <label htmlFor="firstName" className="block my-2 text-left text-sm font-medium text-gray-900">
                                Your email
                            </label>
                            <input
                                type="text"
                                id="email"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.email && formik.touched.email ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Your Email"
                                {...formik.getFieldProps('email')}
                            />
                            {formik.errors.email && formik.touched.email && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                            )}
                        </div>
                        <div className="w-1/2 pr-2 ">
                            <label htmlFor="firstName"
                                className="block my-2 text-left text-sm font-medium text-gray-900">
                                Mobile Number
                            </label>
                            <input
                                {...formik.getFieldProps('mob_no')}
                                type="tel"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.mob_no && formik.touched.mob_no ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Mobile Number"
                            //value={registerForm.mobileNumber}
                            />
                            {formik.errors.mob_no && formik.touched.mob_no && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.mob_no}</div>
                            )}
                        </div>

                    </div>

                    <div className="flex flex-row">

                        <div className="w-1/2 pr-2 ">
                            <label htmlFor="firstName"
                                className="block my-2 text-left 
                      text-sm font-medium text-gray-900">
                                Job Designation
                            </label>
                            <input type="tel"
                                className="shadow-sm bg-gray-50 border
                      border-gray-300 text-gray-900 
                      text-sm rounded-lg block w-full p-2.5"
                                placeholder="Job Organization"
                                //value={registerForm.jobDesignation}
                                {...formik.getFieldProps('jobDesignation')}
                            />
                        </div>
                        <div className='w-1/2 pr-2 '>
                            <label htmlFor="institution"
                                className="block my-2 text-left text-sm 
                  font-medium text-gray-900">
                                Organization
                            </label>

                            <input type="text"
                                className="shadow-sm bg-gray-50 border
                      border-gray-300 text-gray-900 
                      text-sm rounded-lg block w-full p-2.5"
                                placeholder="Organization"
                                //value={registerForm.organization}
                                {...formik.getFieldProps('organization')}
                            />

                        </div>
                    </div>
                    <div className="flex flex-row">

                        <div className="w-1/2 pr-2 ">
                            <label htmlFor="firstName"
                                className="block my-2 text-left 
text-sm font-medium text-gray-900">
                                Linked Profile Link
                            </label>
                            <input type="tel"
                                className="shadow-sm bg-gray-50 border
border-gray-300 text-gray-900 
text-sm rounded-lg block w-full p-2.5"
                                placeholder="LinkedIn Profile Link"
                                //value={registerForm.jobDesignation}
                                {...formik.getFieldProps('linked_in_link')}
                            />
                        </div>
                        <div className='w-1/2 pr-2 '>
                            <label htmlFor="institution"
                                className="block my-2 text-left text-sm 
font-medium text-gray-900">
                                Twitter Profile Link
                            </label>

                            <input type="text"
                                className="shadow-sm bg-gray-50 border
border-gray-300 text-gray-900 
text-sm rounded-lg block w-full p-2.5"
                                placeholder="Twitter Profile Link"
                                //value={registerForm.organization}
                                {...formik.getFieldProps('twitter_link')}
                            />

                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="formFileSm"
                            className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">Profile Picture</label>
                        <input
                            className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                            id="formFileSm"
                            type="file" onChange={onFileSelect}
                            accept="image/*" />
                    </div>

                    <div className="flex flex-1 mt-4 items-center text-right">
                        <button
                            type="submit"
                            className="p-2 text-right text-white border-green-600 bg-green-600 hover:scale-105 items-end"
                            disabled={formik.isSubmitting}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Dialog>
        </div>
    )
}
