import React, { useEffect, useState, useRef } from 'react'

import { EvenItemComponent } from '../events/event-item/event-item.component';
import GalleryDTO from '../../types/GalleryDTO';
import { SectionHeaderComponent } from '../section-header/section-header.component';
import { GalleryItemComponent } from './gallery-item/gallery-item.component';
import TargetComponent from '../../types/TargetComponent';
import { getData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL';
import { sourcePortalName } from '../../common/constant';

export const GalleryComponent = (targetComponent: TargetComponent) => {

    const isPageLoaded = useRef(false);

    const [gallery, setGallery] = useState<GalleryDTO[]>([]);

    let galleryItems: GalleryDTO[] = [];

    const fetchData = async () => {
        getData(getURL('GET', 'ALUMNI_GALLERY', [sourcePortalName()], true), (resp: any) => {

            if (resp.status === 200 && resp.statusText === 'OK') {



                for (var i = 0; i < resp.data.data.length; i++) {

                    let galleryItem: GalleryDTO = {
                        id: resp.data.data[i].gallery_id,
                        title: resp.data.data[i].title,
                        description: resp.data.data[i].body,
                        createdAt: resp.data.data[i].created_at,
                        photos: []
                    };

                    var iteration = 0;
                    const photoResponse = getData(getURL('GET', 'ALUMNI_GALLERY_PHOTO', [sourcePortalName(), resp.data.data[i]["gallery_id"]], true), (resp2: any) => {

                        if (resp2.status === 200 && resp2.statusText === 'OK') {

                            //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Alumni Registered', life: 3000 });

                            galleryItem.photos.push(resp2.data.data[0]["image_path"]);
                            galleryItems.push(galleryItem);


                        }

                        if (resp.data.data.length - 1 == iteration) {
                            if (targetComponent.componentName == "HOME") {
                                setGallery(galleryItems.slice(0, targetComponent.numberOfRecords));
                            } else {
                                setGallery(galleryItems);
                            }
                        }

                        iteration = iteration + 1;
                    }, (err: any) => {
                        //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                        console.log(err);

                    });



                }



            }

        }, (err: any) => {
            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
            console.log(err);

        });


    }

    useEffect(() => {
        if (isPageLoaded.current == true) return;
        fetchData();
        isPageLoaded.current = true;
    }, [gallery.length]);


    if (gallery.length == 0) {
        return <>No Data Available</>
    }



    return (
        <div className='container my-4 mx-auto px-4 md:px-12'>
            <div className='flex flex-col'>
                <SectionHeaderComponent title='Gallery' navigationPath='/gallery' showViewMoreButton={targetComponent.showViewMoreButton} />
                <div>
                    <div className="container mx-auto px-4 md:px-12">
                        <div className="flex flex-wrap -mx-1 lg:-mx-3">

                            {gallery.map(item => {
                                return <GalleryItemComponent {...item} key={item.id} />
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
