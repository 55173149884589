import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useFormik, useFormikContext } from 'formik';
import { login, setIsAuthenticated } from '../../common/auth-constant'
import { getURL } from '../../api/URL';
import { getData, postData } from '../../api/AxisConfig';
import { Toast } from 'primereact/toast';
import { sourcePortalName } from '../../common/constant';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
export const LoginComponent = () => {

    const navigate = useNavigate();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [response, setResponse] = useState("");

    const emptyForm = {
        userName: '',
        password: ''
    }

    const validationSchema = Yup.object().shape({
        userName: Yup.string().required('User Name is required'),
        password: Yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: emptyForm,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setIsFormSubmitted(true);
            const formData = new FormData();
            formData.append('userName', values["userName"]);
            formData.append('password', values["password"]);

            postData(getURL('CREATE', 'ALUMNI_LOGIN', [], true), formData, (resp: any) => {

                if (resp.status === 200 && resp.statusText === 'OK') {

                    //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Alumni Registered', life: 3000 });
                    setResponse("SUCCESSFUL");

                    const formData2 = new FormData();
                    formData2.append('id', resp.data.data["profile_id"]);
                    formData2.append('token', resp.data.data["token"]);

                    postData(getURL('GET', 'USERS', [], true), formData2, (resp: any) => {

                        if (resp.status === 200 && resp.statusText === 'OK') {
                            login(resp.data.data);
                            setIsAuthenticated(true);
                            navigate("/alumni-corner/my-profile");
                        }
                    },
                        (err: any) => {
                            //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                            console.log(err);
                            setResponse("ERROR");
                        }
                    );


                }

            }, (err: any) => {
                //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                console.log(err);
                setResponse("ERROR");
            });


            setTimeout(() => {
                setIsFormSubmitted(false);
                setResponse("");
            }, 5000);

            formik.resetForm();
        },
    });



    return (
        <div className='login-form'>
            {isFormSubmitted && response == 'SUCCESSFUL' ? <div className='text-center bg-green-700 text-white  p-4 m-3'>Login succesfull</div> : (isFormSubmitted && response == 'ERROR') ? <div className='text-center bg-red-700 text-white font-semibold p-4 m-3'>Error while login...</div> : ''}

            <div className="py-2  mx-auto  bg-white p-4 shadow">

                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-row">
                        <div className="w-full">
                            <label htmlFor="username"
                                className="block my-2 text-left 
                  text-sm font-medium text-gray-900">
                                User Name
                            </label>
                            <input type="text"
                                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.userName && formik.touched.userName ? 'border-red-500' : ''
                                    }`}
                                placeholder="Enter Username"
                                {...formik.getFieldProps('userName')} />
                            {formik.errors.userName && formik.touched.userName && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.userName}</div>
                            )}
                        </div>
                    </div>
                    <div className="w-full ">
                        <label htmlFor="password"
                            className="block my-2 text-left text-sm 
              font-medium text-gray-900">
                            Password
                        </label>
                        <input type="password"
                            className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 ${formik.errors.password && formik.touched.password ? 'border-red-500' : ''
                                }`}
                            placeholder='Password'
                            {...formik.getFieldProps('password')} />
                        {formik.errors.password && formik.touched.password && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                        )}
                    </div>


                    <div className='flex flex-1 mt-4 items-center'>

                        <button
                            type="submit"
                            className="p-2 text-right text-white border-green-600 bg-green-600 hover:scale-105 items-end"
                            disabled={formik.isSubmitting}
                        >
                            Login
                        </button>
                    </div>
                </form>
            </div>

        </div>
    )
}
