import { sourcePortalName } from "./constant";
const portal = "Alumni" + sourcePortalName()
export const isAuthenticated = () => {
    let val = localStorage.getItem("isAuthenticated" + portal) ? localStorage.getItem("isAuthenticated" + portal) : 'false';
    console.log("isAuthenticated::" + val);
    return val;
}

export const login = (profile) => {
    console.log(profile);
    localStorage.setItem("profile" + portal, JSON.stringify(profile));

}

export const setIsAuthenticated = (value) => {
    localStorage.setItem("isAuthenticated" + portal, value);
}

export const getProfile = () => {
    return localStorage.getItem("profile" + portal);
}

export const logout = () => {
    localStorage.removeItem("profile" + portal);
    localStorage.removeItem("isAuthenticated" + portal);
}