import React, { useEffect, useState } from 'react'
import { AlumniCornerHeaderComponent } from '../components/alumni-corner-header/alumni-corner-header.component'
import { Image } from 'primereact/image'
import { getProfile } from '../common/auth-constant';
import { useParams } from 'react-router-dom';
import { postData } from '../api/AxisConfig';
import { getURL } from '../api/URL';
import UserProfile from '../types/Profile';
import { format } from 'date-fns';

export const AlumniProfileLayout = () => {

    const { id } = useParams();
    const [profile, setProfile] = useState<UserProfile>();


    useEffect(() => {

        const prof: any = getProfile();
        const userProfile = JSON.parse(prof);

        const formData2 = new FormData();
        formData2.append('login_profile_id', userProfile["profile_id"]);
        formData2.append('alumni_profile_id', id + "");

        postData(getURL('ALUMNI-PROFILE', 'USERS', [], true), formData2, (resp: any) => {

            if (resp.status === 200 && resp.statusText === 'OK') {
                console.log(resp.data.data);
                const userProfile = resp.data.data[0];

                const tempProfile: UserProfile = {
                    profile_id: userProfile["profile_id"],
                    fname: userProfile["fname"],
                    lname: userProfile["lname"],
                    education: userProfile["education"],
                    email: userProfile["email"],
                    mob_no: userProfile["mob_no"],
                    basic_information: userProfile["basic_information"],
                    job_designation: userProfile["job_designation"],
                    passout_year: format(new Date(userProfile["passout_year"] + ""), 'yyyy'),
                    organization: userProfile["organization"],
                    linked_in_link: userProfile["linked_in_link"],
                    twitter_link: userProfile["twitter_link"],
                    profile_pic: userProfile["profile_pic"]
                }

                setProfile(tempProfile);
            }
        },
            (err: any) => {
                //toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while Alumni Registration !!', life: 3000 });
                console.log(err);

            }
        );
    }, []);



    return (
        <div className='flex flex-col'>
            <AlumniCornerHeaderComponent title='Alumni Profile' />
            <div className='flex flex-row'>
                <div className="bg-gray-100 min-h-screen p-4 w-[100%]">
                    <div className="bg-white p-4 rounded-lg shadow-md mx-auto">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-4">
                                <Image
                                    src={profile?.profile_pic == '' ? process.env.PUBLIC_URL + '/avatar.jpg' : profile?.profile_pic}
                                    preview
                                    zoomSrc={profile?.profile_pic == '' ? process.env.PUBLIC_URL + '/avatar.jpg' : profile?.profile_pic}
                                    alt="Profile Picture"
                                    className="w-20 h-20 rounded-full"
                                />
                                <div>
                                    <h1 className="text-xl md:text-2xl font-semibold">{profile?.fname + " " + profile?.lname}</h1>
                                    <p className="text-sm md:text-base text-gray-600">{profile?.job_designation}</p>
                                </div>
                            </div>

                        </div>

                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">About</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                {profile?.basic_information}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Education</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                {profile?.education}
                                <br />
                                Passing Year  <b> {profile?.passout_year}</b>
                            </p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Work</h2>
                            <p className="text-sm md:text-base text-gray-600">{profile?.organization}</p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Contact Information</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                Email: {profile?.email}
                                <br />
                                Phone: {profile?.mob_no}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg md:text-xl font-semibold">Social Media</h2>
                            <p className="text-sm md:text-base text-gray-600">
                                LinkedIn: <h6>{profile?.linked_in_link}</h6>
                                <br />
                                Twitter: <h6>{profile?.twitter_link}</h6>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
