import React from 'react'
import { FaBookReader, FaGraduationCap, FaUserCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const HomeStaticComponent = () => {
    return (
        <div className='container my-4 mx-auto px-4 md:px-12 bg-gray-100'>
            <div className="container mx-auto px-4 md:px-12 ">
                <div className="flex flex-wrap -mx-1 lg:-mx-3">
                    <div className="my-4  px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 ">

                        <article className="overflow-hidden rounded-lg shadow-lg bg-white">

                            <div className='flex items-center  p-2 md:p-4 flex-col'>
                                <FaGraduationCap className='text-4xl text-cyan-700 font-extrabold ' />
                            </div>
                            <header className="flex items-center  md:p-4 flex-col">
                                <h1 className="text-base">
                                    <a href="#">
                                        <p className="no-underline hover:underline text-black text-xl font-semibold">Your Batchmates</p>
                                    </a>
                                </h1>
                                <p className="text-gray-600  text-sm p-2">
                                    View our exclusive batchmates directory to know about whereabouts of your batchmates
                                </p>
                            </header>

                            <footer className='flex items-center justify-between leading-tight p-2 md:p-4 flex-col'>
                                <Link to="/register-login" className='bg-cyan-600 text-white p-2 shadow items-center text-center'>My Batchmates</Link>
                            </footer>
                        </article>
                    </div>
                    <div className="my-4  px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 ">


                        <article className="overflow-hidden rounded-lg shadow-lg bg-white">

                            <div className='flex items-center justify-between leading-tight p-2 md:p-4 flex-col'>
                                <FaBookReader className='text-4xl text-cyan-700 font-extrabold' />
                            </div>
                            <header className="flex items-center  p-2 md:p-4 flex-col">
                                <h1 className="text-base">
                                    <a href="#">
                                        <p className="no-underline hover:underline text-black text-xl font-semibold">Alumni Directory</p>
                                    </a>
                                </h1>
                                <p className="text-gray-600  text-sm p-2">
                                    Explore complete alumni directory & connect with alumni with your interests & domain.
                                </p>
                            </header>

                            <footer className='flex items-center justify-between leading-tight p-2 md:p-4 flex-col'>
                                <Link className='bg-cyan-600 text-white p-2 shadow items-center text-center' to="/register-login">View Directory</Link>
                            </footer>
                        </article>


                    </div>
                    <div className="my-4  px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 ">


                        <article className="overflow-hidden rounded-lg shadow-lg bg-white">

                            <div className='flex items-center justify-between leading-tight p-2 md:p-4 flex-col'>
                                <FaUserCircle className='text-4xl text-cyan-700 font-extrabold ' />
                            </div>
                            <header className="flex items-center  p-2 md:p-4 flex-col">
                                <h1 className="text-base">
                                    <a href="#">
                                        <p className="no-underline hover:underline text-black text-xl font-semibold">My Profile</p>
                                    </a>
                                </h1>
                                <p className="text-gray-600 text-sm p-2">
                                    Create & complete your alumni profile and remain connected with all opportunities matching your interest.
                                </p>
                            </header>

                            <footer className='flex items-center justify-between leading-tight p-2 md:p-4 flex-col'>
                                <Link to="/register-login" className='bg-cyan-600 text-white p-2 shadow items-center text-center'>My Profile</Link>
                            </footer>
                        </article>


                    </div>

                </div>
            </div>
        </div>
    )
}
